import React from "react"

const Logo = props => (
  <svg width={132} height={42} {...props}>
		<path className="st0" d="M11.22,13.28c0-2.46-2.83-3.69-5.24-3.69C2.81,9.6,1.1,11.37,1.1,13c0,1.09,0.51,1.79,1.39,1.79
		c0.82,0,1.46-0.57,1.46-1.34c0-1.44-1.05-1.09-1.05-1.85c0-1.15,2.15-1.5,3.07-1.5c1.2,0,2.6,0.35,2.6,1.85v3.93h-0.6
		C4.72,15.87,0,16.75,0,20.29c0,2.15,1.49,3.39,3.96,3.39c2.09,0,3.51-0.54,4.59-1.96h0.06c0.22,1.11,1.29,1.96,2.77,1.96
		c1.36,0,2.19-0.67,2.79-1.71l-0.35-0.25c-0.48,0.67-0.92,1.27-1.65,1.27c-0.86,0-0.95-0.79-0.95-1.39V13.28z M8.58,16.37v4.15
		c0,0.63-1.04,2.41-3.42,2.41c-2.03,0-2.31-1.33-2.31-3.1c0-3.39,2.88-3.45,5.19-3.45H8.58z"/>
		<path className="st0" d="M26.38,23.3h4.83v-0.5h-2.19V0c-0.63,0.73-1.64,0.79-2.64,0.79h-2.55v0.5h2.55v11.54h-0.06
		c-0.58-1.49-2.11-3.23-4.41-3.23c-3.82,0-6.32,3.93-6.32,7.04c0,3.73,2.55,7.05,6.54,7.05c2.2,0,3.7-1.61,4.18-2.68h0.06V23.3z
		 M26.38,18.74c0.03,2.64-2.08,4.45-4.25,4.45c-3.13,0-3.51-1.81-3.51-6.55c0-4.36,0.48-6.55,3.29-6.55c2.65,0,4.47,2.61,4.47,4.93
		V18.74z"/>
		<path className="st0" d="M44.51,23.3h4.83v-0.5h-2.19V0c-0.63,0.73-1.64,0.79-2.64,0.79h-2.55v0.5h2.55v11.54h-0.06
		c-0.58-1.49-2.11-3.23-4.41-3.23c-3.83,0-6.32,3.93-6.32,7.04c0,3.73,2.55,7.05,6.54,7.05c2.2,0,3.7-1.61,4.18-2.68h0.06V23.3z
		 M44.51,18.74c0.03,2.64-2.08,4.45-4.25,4.45c-3.13,0-3.51-1.81-3.51-6.55c0-4.36,0.48-6.55,3.29-6.55c2.65,0,4.47,2.61,4.47,4.93
		V18.74z"/>
		<path className="st0" d="M54.63,10.81h-2.54v-0.49h2.54c1.01,0,2.01-0.06,2.64-0.72V22.8h2.54v0.49h-7.71V22.8h2.54V10.81z" />
		<path className="st0" d="M55.98,2.28c0.89,0,1.62,0.73,1.62,1.62c0,0.89-0.73,1.62-1.62,1.62c-0.89,0-1.62-0.73-1.62-1.62
		C54.36,3.01,55.09,2.28,55.98,2.28"/>
		<path className="st0" d="M64.71,10.81h-2.76v-0.49c3.29,0.06,4.49-1.33,5.02-4.44h0.38v4.44h4.38v0.49h-4.38v10.67
		c0,1.3,0.54,1.72,1.55,1.72c1.59,0,2.28-1.46,2.47-2l0.48,0.1c-0.22,0.76-1.14,2.4-3.49,2.4c-2.21,0-3.66-0.72-3.66-2.81V10.81z"/>
		<path className="st0" d="M76.85,10.81h-2.54v-0.49h2.54c1.01,0,2.01-0.06,2.64-0.72V22.8h2.53v0.49h-7.71V22.8h2.54V10.81z" />
		<path className="st0" d="M78.2,2.28c0.89,0,1.62,0.73,1.62,1.62c0,0.89-0.73,1.62-1.62,1.62c-0.89,0-1.62-0.73-1.62-1.62
		C76.58,3.01,77.31,2.28,78.2,2.28"/>
		<path className="st0" d="M85.53,10.81h-1.84v-0.49h7.25v0.49H88.6l3.99,9.64l3.1-7.07c0.35-0.79,0.54-1.39,0.54-1.74
		c0-0.73-0.89-0.82-1.42-0.82H93.7v-0.49h5.51v0.49c-1.46,0-1.84,0.13-2.5,1.62l-5.16,11.63h-0.25L85.53,10.81z"/>
  </svg>
)

export default Logo
