import React from "react"
import styled from "styled-components"
import Button from "./Button"

const Heading = styled.h3`
  margin-top: 0;
`

const CookieBar = ({ onClick }) => (
    <div className="cookie-bar">
        <Heading>Cookies</Heading>
        <p>
            This site uses cookies. Some of them are essential while other help us
            improve your experience.{" "}
            <a
                href="https://additiv.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
            >
                Learn more
            </a>
        </p>
        <Button className="button-link" onClick={onClick}>Accept</Button>
    </div>
)

export default CookieBar
